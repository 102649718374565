import React from 'react';
import Home from './pages/Home';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Developers from './pages/Developers';
import Api from './pages/Api';
import Apps from './pages/Apps';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import {Routes, Route} from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from "react-ga4";

ReactGA.initialize("G-TYQBPC150K");

function App() {

  return (
    <Router>
      <div className="App">
        <section>
          <div>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/developers" element={<Developers/>}/>
              <Route path="/api" element={<Api/>}/>
              <Route path="/apps" element={<Apps/>}/>
              <Route path="/signup" element={<Signup/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/privacy" element={<Privacy/>}/>
              <Route path="/terms" element={<Terms/>}/>
            </Routes>
          </div>
        </section>

      </div>
    </Router>
  );
}

export default App;
