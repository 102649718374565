import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import '../App.css';

const About = () => {

  return (
    <>
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/"><img src="/yo-72.png"/></Navbar.Brand>
      </Container>
    </Navbar>
    <Container>
      <div className="row home">
        <div className="col">
          <br/>
          <h2>About Us</h2>
          <p>
            Like you, we have missed YoApp. Our team is trying to recreate the experience!
          </p>
          <p>Stay tuned...</p>
          <span className="p-2">
            <a href="mailto:yo@getvocal.com">Contact</a>
          </span>
          <span className="p-2">
            <a href="https://twitter.com/yotifications">Twitter</a>
          </span>
        </div>
      </div>

      <footer>
        <div className="row">
          <div className="col">
            <span className="p-2">
              <a href="/about">About</a>
            </span>
            <span className="p-2">
              <a href="https://blog.yo.im">Blog</a>
            </span>
            <span className="p-2">
              <a href="/terms">Terms</a>
            </span>
            <span className="p-2">
              <a href="/privacy">Privacy</a>
            </span>
            <span className="p-2">
              <a href="/login">Developers</a>
            </span>
          </div>
          <div className="col text-end">
              &copy; {(new Date().getFullYear())}
          </div>
        </div>
      </footer>
    </Container>
    </>
  )
}

export default About
