import React, {useState, useEffect} from 'react';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { NavLink, useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';

import '../App.css';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
          if (user) {
            // setUid(user.uid);
            // setName(user.displayName);
            // getUserSettings(user.uid);
            // checkPushEnabled(user);
            navigate("/developers")
          } else {
            console.log("user is logged out")
          }
        });
    }, []);

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            console.log(user);
            navigate("/developers")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
    }

    return(
        <>
        <Container className="text-white" style={{height: "100vh"}}>
          <div className="img-fluid home" >
          <center>
            <div className="text-white">
              <h1 className="justify-content-center"><span style={{fontSize: 100}}><b>Yo</b></span></h1>
              <br/>
              <div className="col-4 text-center">
                <form>

                      <div className=" space-y-10">
                        <div className="p-1">
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                required
                                className="form-control form-control-lg"
                                placeholder="Email address"
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div>

                        <div className="p-1">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                className="form-control form-control-lg"
                                placeholder="Password"
                                onChange={(e)=>setPassword(e.target.value)}
                            />
                        </div>
                      </div>

                    <br/>
                    <div >
                        <button
                            onClick={onLogin}
                            className="btn btn-dark btn-lg"
                        >
                            Log In
                        </button>
                    </div>

                </form>

            </div>
          </div>
          </center>
        </div>

        <footer>
          <div className="row">
            <div className="col">
              <span className="p-2">
                <a href="mailto:yo@getvocal.com">Contact</a>
              </span>
              <span className="p-2">
                <a href="https://twitter.com/yotifications">Twitter</a>
              </span>
              <span className="p-2">
                <a href="/terms">Terms</a>
              </span>
              <span className="p-2">
                <a href="/privacy">Privacy</a>
              </span>
              <span className="p-2">
                <a href="https://blog.yo.im">Blog</a>
              </span>
              <span className="p-2">
                <a href="/login">Developers</a>
              </span>
            </div>
            <div className="col text-end">
                &copy; {(new Date().getFullYear())}
            </div>
          </div>
        </footer>
      </Container>
      </>
    )
}

export default Login
