import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import React, { useEffect, useState }  from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { doc, setDoc, deleteDoc, query, collection, where, getDocs, addDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2'
import ReactEmbedGist from 'react-embed-gist';

import '../App.css';

function App() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [uid, setUid] = useState('');
  const [appId, setAppId] = useState('');
  const [appTokenCode, setAppTokenCode] = useState('');
  const [appWebhook, setAppWebhook] = useState('');
  const [appWebhookMethod, setAppWebhookMethod] = useState('GET');
  const [appName, setAppName] = useState('');
  const [appAvailable, setAppAvailable] = useState(false);
  const [apps, setApps] = useState([]);
  const [activeTab, setActiveTab] = useState('gettingstarted');

  const handleLogout = () => {
      signOut(auth).then(() => {
        navigate("/");
        console.log("Signed out successfully")
      }).catch((error) => {
        console.log(error);
      });
  }

  async function getApps(userId){
    let appArray = [];
    const q = query(collection(db, "users"), where("uid", "==", userId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let appObj = {
        id: doc.id,
        name: doc.data().username,
        apiToken: doc.data().apiToken,
        uid: doc.data().uid,
        contacts: doc.data().contacts,
        webhook: doc.data().webhook
      }
      appArray.push(appObj);
      // setApps([...apps, appObj]);
    });
    setApps(appArray);
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
          setUid(user.uid);
          setName(user.displayName);
          getApps(user.uid);
        } else {
          console.log("user is logged out")
          navigate("/")
        }
      });
  }, []);

  const updateToken = (appId) => {
    var token = uuidv4();
    setDoc(doc(db, "users", appId), {
      apiToken: token,
    }, { merge: true });
    setAppTokenCode(token);
  }

  const updateWebhook = (e) => {
    e.preventDefault();
    setDoc(doc(db, "users", appId), {
      webhook: {method: appWebhookMethod, url: appWebhook},
    }, { merge: true });
  }

  const checkAppName = async (e) => {
    setAppName(e);
    if(e.length > 3){
      const q = query(collection(db, "users"), where("username", "==", "yo" + e.toLowerCase()));
      const querySnapshot = await getDocs(q);
      if(querySnapshot.docs.length>0 ){
        setAppAvailable(false);
      } else {
        setAppAvailable(true);
      }

    } else {
      setAppAvailable(false);
    }
  }

  const createAppName = async (e) => {
    e.preventDefault();
    if(appAvailable){
      var token = uuidv4();
      // Add a new document with a generated id.
      const docRef = await addDoc(collection(db, "users"), {
        username: "yo" + appName,
        apiToken: token,
        contacts: [],
        uid: uid
      });
      console.log("Document written with ID: ", docRef.id);
      getApps(uid)
      setActiveTab('apps');
      setAppName('');
      setAppAvailable(false);
    }
  }

  const deleteConfirm = async (deleteAppId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#c0c0c0',
      confirmButtonText: 'DELETE!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteApp(deleteAppId)
      }
    })
  }

  const deleteApp = async (deleteAppId) => {
    await deleteDoc(doc(db, "users", deleteAppId));
    getApps(uid);
  }

  const handleTabChange = (mytab) => {
      getApps(uid);
      setAppName('');
      setActiveTab(mytab);
  }

  const editApp = (app) => {
    setAppId(app.id);
    setAppTokenCode(app.apiToken);
    setAppName(app.name)
    if(app.webhook){
      setAppWebhookMethod(app.webhook.method)
      setAppWebhook(app.webhook.url)
    }
    setActiveTab('appTab');

  }

  return (
    <>
    <div className="content">
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/developers"><img src="/yo-72.png"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="justify-content-end" style={{ width: "100%" }}>
              <Nav.Link onClick={handleLogout}>Sign Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr className="section-title-hr"/>
      <Container className="content">
        <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={(k) => handleTabChange(k)}>
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column" >
                <Nav.Item>
                  <Nav.Link href="/developers">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="gettingstarted">New App</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="apps">Existing Apps</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="docs">Documentation</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                <Tab.Pane eventKey="gettingstarted">
                <h2>New App</h2>
                <br/>
                <p>
                Create your very own Yo app with its own API token to interact with Yo users.
                </p>
                <p>
                Give your app a name (note: all app names start with "yo" and have a 4 character minimum):
                </p>

                <InputGroup className="mb-3">
                  <span style={{fontSize: 24}}>yo</span>
                  <input
                    id="appname"
                    name="appname"
                    type="text"
                    value={appName}
                    required
                    className={`form-control ${appAvailable ? 'is-valid' : 'is-invalid'}`}
                    placeholder="appname"
                    onChange={(e)=>checkAppName(e.target.value)}
                  />
                </InputGroup>

                  <div >
                      <Button
                          onClick={createAppName}
                          className="btn btn-dark btn-lg"
                          disabled={appAvailable ? false : true}
                      >
                          Create
                      </Button>
                  </div>

                <br/>

                </Tab.Pane>
                <Tab.Pane eventKey="apps">
                  <h2>My Apps</h2>
                  <br/>
                  <div>
                    <Table responsive striped>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>API Token</th>
                        </tr>
                      </thead>
                      <tbody>
                        {apps.map((app) =>
                          <tr key={app.id}>
                            <td><Nav.Link onClick={() => editApp(app)}><u>{app.name}</u></Nav.Link></td>
                            <td>{app.apiToken}</td>
                            <td>
                              <Button
                                  onClick={() => deleteConfirm(app.id)}
                                  className="btn btn-danger"
                              >
                                  Delete
                              </Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="appTab">
                  <h2>{appName.toUpperCase()}</h2>
                  <br/>
                  <h4>Yo App API Token: &nbsp;
                    <span style={{color: "#813a9d"}}><b>{appTokenCode}</b></span>
                    <Button
                        onClick={() => updateToken(appId)}
                        className="btn btn-dark btn-lg"
                    >
                        Regenerate Token
                    </Button>
                  </h4>
                  <br/>
                  <h2>Yo App Webhook</h2>
                  <br/>
                  <p>
                  Add a WebHook callback to API so that we send a GET or POST request to your URL when someone Yo's your app.
                  Every time a user Yo's your app, our servers will hit this callback URL like this:
                  </p>
                  <p>
                  GET https://yourcallbackurl.com/yourendpoint?username=THEYOER
                  </p>

                  <InputGroup className="mb-3">
                    <div className="input-group-prepend">
                      <DropdownButton
                        variant="outline-primary"
                        title={appWebhookMethod}
                        id="webhook-method"
                      >
                        <Dropdown.Item onClick={() => setAppWebhookMethod('GET')}>GET</Dropdown.Item>
                        <Dropdown.Item onClick={() => setAppWebhookMethod('POST')}>POST</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <input
                      id="callback"
                      name="callback"
                      type="text"
                      value={appWebhook}
                      required
                      className="form-control "
                      placeholder="https://"
                      onChange={(e)=>setAppWebhook(e.target.value)}
                    />
                  </InputGroup>

                    <div >
                        <Button
                            onClick={updateWebhook}
                            className="btn btn-dark btn-lg"
                        >
                            Update
                        </Button>
                    </div>

                  <br/>
                </Tab.Pane>
                <Tab.Pane eventKey="docs">
                  <h2>App Documentation</h2>
                  <br/>
                  <p>
                    When a user Yo’s your Yo App, we automatically add their username to your app’s contacts list. Likewise, when they delete your app from their list, we remove their username from your App’s contact list.
                  </p>
                  <p>
                    Your app is hosted on your own servers or cloud. Using either our /yo or /yoall APIs, your app can send a Yo to one or all of your contacts with a simple API call. For instance our yoclock app intiates a /yoall API call to all of its contacts every hour on the hour. Your app could do something similar when your favorite sports team scores a point or everytime a new story makes the front page of HackerNews, etc.
                  </p>
                  <img src="yoapp-diagram.png" alt="Yo App Diagram"/>
                  <p>
                    Here's an example of our yoclock Yo App which Yo's users every hour on the hour.
                  </p>
                  <ReactEmbedGist
                    gist="chrismatthieu/b2d90b3e31b40817b37ec15c6bcd1a2e"
                  />
                  <p>
                    While Yo Apps are designed to be simple, you can imagine them being even more powerful by also leveraging our webhooks to feed usernames into the app in real-time as well as the ability to send Yo’s to hashtags. You may even leverage additional capabilities by having your Yo App call IFTTT and Zapier triggers.
                  </p>
                  <br/>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

      </Container>
    </div>
    </>
  );
}

export default App;
