import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React, { useEffect, useState }  from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from '../firebase';


import '../App.css';

function App() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [uid, setUid] = useState('');

  const handleLogout = () => {
      signOut(auth).then(() => {
        navigate("/");
        console.log("Signed out successfully")
      }).catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
          setUid(user.uid);
          setName(user.displayName);
        } else {
          console.log("user is logged out")
          navigate("/")
        }
      });
  }, []);

  return (
    <>
    <div className="content">
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/developers"><img src="/yo-72.png" alt="Yo logo"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="justify-content-end" style={{ width: "100%" }}>
              <Nav.Link onClick={handleLogout}>Sign Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr className="section-title-hr"/>
      <Container className="content">
        <h2>Yo {name? name.charAt(0).toUpperCase() + name.slice(1) : ""}!
        <br/>
        <center>Welcome to the Yo Developer tools!</center>
        </h2>
        <br/>
        <Row className="justify-content-md-center">
          <Col style={{backgroundColor: '#934AB0'}} sm={5}>
            <a href="/api">
              <center><img src="/tools.png" height="300px" alt="API tools"/></center>
            </a>
            <br/>
            <Button
                onClick={() => navigate("/api")}
                className="btn btn-dark btn-lg"
            >
                API Docs, Token, Webhooks
            </Button>
          </Col>
          <Col md="auto"/>
          <Col style={{backgroundColor: '#934AB0'}} sm={5}>
            <a href="/apps">
              <center><img src="/tools.svg" height="300px" alt="Yo apps"/></center>
            </a>
            <br/>
            <Button
                onClick={() => navigate("/apps")}
                className="btn btn-dark btn-lg"
            >
                Yo Apps
            </Button>
          </Col>
        </Row>

        <footer className="footer">
          <div className="row">
            <div className="col">
              <span className="p-2">
                <a href="/about"><span style={{color: 'black'}}>About</span></a>
              </span>
              <span className="p-2">
                <a href="https://blog.yo.im"><span style={{color: 'black'}}>Blog</span></a>
              </span>
              <span className="p-2">
                <a href="/terms"><span style={{color: 'black'}}>Terms</span></a>
              </span>
              <span className="p-2">
                <a href="/privacy"><span style={{color: 'black'}}>Privacy</span></a>
              </span>
              <span className="p-2">
                <a href="/login"><span style={{color: 'black'}}>Developers</span></a>
              </span>
            </div>
            <div className="col text-end">
                &copy; {(new Date().getFullYear())}
            </div>
          </div>
        </footer>
      </Container>
    </div>
    </>
  );
}

export default App;
