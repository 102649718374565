import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import '../App.css';

const Privacy = () => {

  return (
    <>
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/"><img src="/yo-72.png"/></Navbar.Brand>
      </Container>
    </Navbar>
    <Container>
      <div className="row">
        <div className="col" >
        <br/>
        <h2>PRIVACY POLICY</h2>
        <p>Last updated on October 31, 2023.</p>

        <h4>OPERATIONS</h4>
        <p>Yo (the "App" and/or the "Service") is owned and operated by GetVocal, inc. ("we", "us" or "our").</p>

        <h4>USER CONSENT</h4>
        <p>When you use this App, you consent to the collection, use and disclosure of your information as described in this Privacy Policy.</p>

        <p>This Privacy Policy explains what information of yours will be collected by us, and stored on our servers, when you make use of the App and how the information may be used.</p>

        <p>We will not use or share your information with anyone except as described in this Privacy Policy.</p>

        <p>This Privacy Policy may be updated from time to time and therefore we recommend you check back periodically for the most updated form of Privacy Policy.</p>

        <h4>INFORMATION COLLECTED</h4>
        <p>Statistical Information</p>

        <p>We may collect and aggregate non-identifying information regarding your use of our Service, including, inter alia, username, UID and 'YO's sent and received ("Statistical Information").</p>
        <p>In addition, we may also collect information about the device you’re using the App on, including what type of device it is, what operating system you’re using, device settings, unique device identifiers, and crash data. Whether we collect some or all of this information often depends on what type of device you’re using and its settings. To learn more about what information your device makes available to us, please also check the policies of your device manufacturer or software provider.</p>

        <p>Mobile Phone Number</p>

        <p>When you register for, access and/or elect to use the 'Find Friends' feature in the App (the "Feature"), your mobile phone number (your "Number") will be retained by us. We will use your Number solely for the purpose of providing you and the other users of the Feature with the services offered through the Feature. By accessing the Feature you authorize us to store and use your Number in accordance with the terms herein.</p>

        <h4>USE OF COLLECTED INFORMATION</h4>
        <p>Statistical Information</p>

        <p>Statistical Information shall be used to ensure the adequate operation, improve, modify, cancel, enrich or adjust the Service, in general or to your area of interest.</p>
        <p>We reserve the right to use and disclose Statistical Information (in the form collected) to third parties, including, inter alia, potential and actual advertisers, sponsors, business partners, investors, affiliates and consultants, at our sole discretion.</p>

        <p>Mobile Phone Number</p>

        <p>Your Number shall be used by us solely in order to allow and enable the proper and enjoyable use of the services offered through the Feature and in accordance with any applicable law.</p>

        <p>We will not disclose your Number to any third party, unless:</p>

        <p>required under law, regulation, court order or equivalent.</p>

        <p>to report or prevent suspected criminal activity.</p>

        <p>in case of a suspected emergency that may threaten any person or the general public.</p>

        <p>to protect our rights and/to enforce our Terms of Use.</p>

        <h4>INFORMATION SECURITY</h4>
        Note that although we take measures to secure the information and reduce the risk of unwanted disclosure, we cannot guarantee the information will not be exposed as a result of unauthorized penetration to our servers.

        <h4>CHANGES TO PRIVACY POLICY</h4>
        <p>We may at any time make changes to this Privacy Policy. We shall notify you of any changes by posting notice of such changes within the App. Your continued use of the Service after we make any such changes constitutes your binding acceptance of those changes. Notwithstanding, changes to our Privacy Policy, regarding information we may collect or use or disclosure of collected information, shall only take effect thirty days (30) after posting notice of such changes within the App.</p>

        <p>If you have any questions about this Policy, please contact us at contact@justyo.co Please also see our Terms of Use which detail our policies in connection with the App and the use thereof, at https://yo.im/terms</p>


        </div>
      </div>

      <footer>
        <div className="row">
          <div className="col">
            <span className="p-2">
              <a href="/about">About</a>
            </span>
            <span className="p-2">
              <a href="https://blog.yo.im">Blog</a>
            </span>
            <span className="p-2">
              <a href="/terms">Terms</a>
            </span>
            <span className="p-2">
              <a href="/privacy">Privacy</a>
            </span>
            <span className="p-2">
              <a href="/login">Developers</a>
            </span>
          </div>
          <div className="col text-end">
              &copy; {(new Date().getFullYear())}
          </div>
        </div>
      </footer>
    </Container>
    </>
  )
}

export default Privacy
