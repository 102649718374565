import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import React, { useEffect, useState }  from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { getDoc, doc, setDoc } from "firebase/firestore";
import { CopyBlock, dracula } from "react-code-blocks";
import { v4 as uuidv4 } from 'uuid';

import '../App.css';

function App() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [uid, setUid] = useState('');
  const [tokenCode, setTokenCode] = useState('');
  const [webhook, setWebhook] = useState('');
  const [webhookMethod, setWebhookMethod] = useState('GET');

  const handleLogout = () => {
      signOut(auth).then(() => {
        navigate("/");
        console.log("Signed out successfully")
      }).catch((error) => {
        console.log(error);
      });
  }

  async function getUserSettings(uid, name){
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()) {
      // setName(docSnap.data().userName);
      setTokenCode(docSnap.data().apiToken);
      if(docSnap.data().webhook){
        setWebhookMethod(docSnap.data().webhook.method);
        setWebhook(docSnap.data().webhook.url);
      }
    }
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
          setUid(user.uid);
          setName(user.displayName);
          getUserSettings(user.uid, user.displayName);
        } else {
          console.log("user is logged out")
          navigate("/")
        }
      });
  }, []);

  const updateToken = (userId) => {
    var token = uuidv4();
    setTokenCode(token);
    setDoc(doc(db, "users", userId), {
      apiToken: token,
    }, { merge: true });
  }

  const updateWebhook = (e) => {
    e.preventDefault();
    setDoc(doc(db, "users", uid), {
      webhook: {method: webhookMethod, url: webhook},
    }, { merge: true });
  }


  return (
    <>
    <div className="content">
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/developers"><img src="/yo-72.png"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="justify-content-end" style={{ width: "100%" }}>
              <Nav.Link onClick={handleLogout}>Sign Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr className="section-title-hr"/>
      <Container className="content">
        <Tab.Container id="left-tabs-example" defaultActiveKey="gettingstarted">
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column" >
                <Nav.Item>
                  <Nav.Link href="/developers">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="gettingstarted">Getting Started</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="yo">/Yo</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="yoall">/YoAll</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="me">/Me</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="contacts">/Contacts</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="hashtags">/Hashtags</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="webhooks">WebHooks</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                <Tab.Pane eventKey="gettingstarted">
                  <h4>Your Developer Token: &nbsp;
                    <span style={{color: "#813a9d"}}><b>{tokenCode}</b></span>
                    <Button
                        onClick={() => updateToken(uid)}
                        className="btn btn-dark btn-lg"
                    >
                        Regenerate Token
                    </Button>
                  </h4>
                  <br/>
                  <p>Yo is a ridiculously simple notification platform. Here are a few details about Yo:</p>
                  <ul>
                    <li>A "Yo" is a simple push notification.</li>
                    <li>It's perfect for the times you just need a simple ping that let's you know something happened and the predefined context fills in the data that is not provided in the notification.</li>
                    <li>Yo can be used between people, but with this simple API you can get notified about stuff that happens from computers and organizations as well.</li>
                    <li>Sending a Yo is as easy as sending a POST request. All you need is a token that determines who the Yo is from, and the username of the recipient.</li>
                  </ul>
                  <br/>
                </Tab.Pane>
                <Tab.Pane eventKey="yo">
                  <h2>POST /yo</h2>
                  <p>Send a Yo to yourself or friend:</p>
                  <ul>
                  <li>Run the following cURL command in your terminal</li>
                  </ul>

                  <CopyBlock
                    text={`curl --data "username=${name}&api_token=${tokenCode}" https://api.yo.im/yo/`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  OR <br/>
                  <CopyBlock
                    text={`curl -X POST https://api.yo.im/yo -H 'Content-Type: application/json' -d '{"username":"${name}","api_token":"${tokenCode}"}'`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  <br/>
                  <p>Send a Yo to everyone following a specific hashtag:</p>
                  <ul>
                  <li>Run the following cURL command in your terminal</li>
                  </ul>
                  <CopyBlock
                    text={`curl --data "hashtag=#cats&api_token=${tokenCode}" https://api.yo.im/yo/`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  OR <br/>
                  <CopyBlock
                    text={`curl -X POST https://api.yo.im/yo -H 'Content-Type: application/json' -d '{"hashtag":"#cats","api_token":"${tokenCode}"}'`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  <br/>
                  <h4>Location</h4>
                  <p>Note: You can optionally send location with Yos by adding "location" to the body as follows:<br/>
                    <code>{`&location={\\"coords\\":{\\"latitude\\":33.5016,\\"longitude\\":-111.9313}}`}</code>
                  </p>

                  <CopyBlock
                    text={`curl --data "username=${name}&api_token=${tokenCode}&location={\\"coords\\":{\\"latitude\\":33.5016,\\"longitude\\":-111.9313}}" https://api.yo.im/yo/`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />

                </Tab.Pane>
                <Tab.Pane eventKey="yoall">
                  <h2>POST /yoall</h2>
                  <p>Send a Yo to all of your contacts who also follow you back:</p>
                  <ul>
                  <li>Run the following cURL command in your terminal</li>
                  </ul>

                  <CopyBlock
                    text={`curl --data "api_token=${tokenCode}" https://api.yo.im/yoall/`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  OR <br/>
                  <CopyBlock
                    text={`curl -X POST https://api.yo.im/yoall -H 'Content-Type: application/json' -d '{"api_token":"${tokenCode}"}'`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  <br/>
                </Tab.Pane>
                <Tab.Pane eventKey="me">
                  <h2>GET /me</h2>
                  <p>Get your user data:</p>
                  <h4>cURL Example to get your user data:</h4>
                  <CopyBlock
                    text={`curl "https://api.yo.im/me?api_token=${tokenCode}"`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  <br/>
                </Tab.Pane>
                <Tab.Pane eventKey="contacts">
                  <h2>GET /contacts </h2>
                  <p>Get a list of your contacts:</p>
                  <h4>cURL Example to get user contacts:</h4>
                  <CopyBlock
                    text={`curl "https://api.yo.im/contacts?api_token=${tokenCode}"`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  <br/>

                  <h2>POST /contacts </h2>
                  <p>Add a contact to your list contacts:</p>
                  <h4>cURL Example to add a user contact:</h4>
                  <CopyBlock
                    text={`curl --data "username=jimmy&api_token=${tokenCode}" https://api.yo.im/contacts`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  <br/>

                  <h2>DELETE /contacts</h2>
                  <p>Delete a contact to your list contacts:</p>
                  <h4>cURL Example to delete a user contact:</h4>
                  <CopyBlock
                    text={`curl -X DELETE "https://api.yo.im/contacts?username=jimmy&api_token=${tokenCode}"`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  <br/>
                </Tab.Pane>
                <Tab.Pane eventKey="hashtags">
                  <h2>GET /hashtags </h2>
                  <p>Get a list of your followed hashtags:</p>
                  <h4>cURL Example to get user hashtags:</h4>
                  <CopyBlock
                    text={`curl "https://api.yo.im/hashtags?api_token=${tokenCode}"`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  <br/>

                  <h2>POST /hashtags </h2>
                  <p>Add a hashtag to your list hashtags:</p>
                  <h4>cURL Example to add a user hashtag:</h4>
                  <CopyBlock
                    text={`curl --data "hashtag=cats&api_token=${tokenCode}" https://api.yo.im/hashtags`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  <br/>

                  <h2>DELETE /hashtags</h2>
                  <p>Delete a hashtag to your list hashtags:</p>
                  <h4>cURL Example to delete a user hashtag:</h4>
                  <CopyBlock
                    text={`curl -X DELETE "https://api.yo.im/hashtags?hashtag=cats&api_token=${tokenCode}"`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                  <br/>
                </Tab.Pane>
                <Tab.Pane eventKey="webhooks">
                  <h2>WEBHOOKS</h2>
                  <br/>
                  <p>
                  Add a WebHook callback to your account so that we send a GET or POST request to your specified URL when someone Yo's you.
                  Every time a user Yo's your username, our servers will hit your callback URL like this:
                  </p>
                  <p>
                  GET https://yourcallbackurl.com/yourendpoint?username=THEYOER
                  </p>

                  <InputGroup className="mb-3">
                    <div className="input-group-prepend">
                      <DropdownButton
                        variant="outline-primary"
                        title={webhookMethod}
                        id="webhook-method"
                      >
                        <Dropdown.Item onClick={() => setWebhookMethod('GET')}>GET</Dropdown.Item>
                        <Dropdown.Item onClick={() => setWebhookMethod('POST')}>POST</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <input
                      id="callback"
                      name="callback"
                      type="text"
                      value={webhook}
                      required
                      className="form-control "
                      placeholder="https://"
                      onChange={(e)=>setWebhook(e.target.value)}
                    />
                  </InputGroup>

                    <div >
                        <Button
                            onClick={updateWebhook}
                            className="btn btn-dark btn-lg"
                        >
                            Update
                        </Button>
                    </div>

                  <br/>
                  <br/>
                  <p>
                  You can also use our general purpose webhooks to add Yo notifications to your GitHub pushes, Heroku deployments, CircleCI, etc.
                  </p>
                  <p>
                  POST https://api.yo.im/hook/USERNAME
                  </p>
                  <h4>cURL Example to POST a Yo webhook notification</h4>
                  <CopyBlock
                    text={`curl -X POST https://api.yo.im/hook/${name}`}
                    language={"bash"}
                    showLineNumbers={false}
                    wrapLines
                    theme={dracula}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

      </Container>
    </div>
    </>
  );
}

export default App;
