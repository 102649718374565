import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import '../App.css';

const Home = () => {

  return (
    <>
    <Container>

      <div className="row home">
        <div className="col d-flex align-items-center justify-content-center">
          <div className="row text-center">
            <h1 className="justify-content"><span style={{fontSize: 100}}><b>Yo</b></span> <span style={{fontSize: 18}}>Simple notifications.</span></h1>
            <p>
  						<a href="https://apps.apple.com/us/app/yoim/id6470100524"><img className="img-fluid d-inlin-block" src="/btn-app-store.png" alt="Download from App Store" width="185" /></a>
  						<a href="https://play.google.com/store/apps/details?id=im.yo"><img className="img-fluid d-inlin-block" src="/btn-google-play.png" alt="Download from Google Play"  width="200" /></a>
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-center justify-content-center">
          <h1 className="justify-content">
            <img className="img-fluid d-inlin-block" src="/ios1.png" alt="Yo - iOS screenshot" width="300" />
          </h1>
        </div>
      </div>
      <footer>
        <div className="row">
          <div className="col">
            <span className="p-2">
              <a href="/about">About</a>
            </span>
            <span className="p-2">
              <a href="https://blog.yo.im">Blog</a>
            </span>
            <span className="p-2">
              <a href="/terms">Terms</a>
            </span>
            <span className="p-2">
              <a href="/privacy">Privacy</a>
            </span>
            <span className="p-2">
              <a href="/login">Developers</a>
            </span>
          </div>
          <div className="col text-end">
              &copy; {(new Date().getFullYear())}
          </div>
        </div>
        <img src="https://yoclock-be04a11835bc.herokuapp.com/yowebsite/93eeecf6-ae30-45b6-b5c3-b1b12a5f10b8"/>
      </footer>
    </Container>
    </>
  )
}

export default Home
