import React, {useState, useEffect} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  // sendEmailVerification,
  updateProfile  } from 'firebase/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from '../firebase';

import '../App.css';

const Signup = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
          if (user) {
            // setUid(user.uid);
            // setName(user.displayName);
            // getUserSettings(user.uid);
            // checkPushEnabled(user);
            navigate("/api")
          } else {
            console.log("user is logged out")
          }
        });
    }, []);

    const onSubmit = async (e) => {
      e.preventDefault()

      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            updateProfile(auth.currentUser, { displayName: userName }).catch(
              (err) => console.log(err)
            );
            navigate("/api")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            // ..
        });

        // await sendEmailVerification(auth.currentUser).catch((err) =>
        //   console.log(err)
        // );
    }



  return (
    <>
    <Container className="text-white" style={{height: "100vh"}}>
      <div className="img-fluid home">
      <center>
        <div className="text-white">
          <h1 className="justify-content-center"><span style={{fontSize: 100}}><b>Yo</b></span></h1>
          <br/>
          <div className="col-4 text-center">
            <form onSubmit={onSubmit} className=" " >

                <div className=" space-y-10">
                    <div className="p-1">
                        <input
                            label="Username"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            name="username"
                            type="text"
                            required
                            className="form-control form-control-lg"
                            placeholder="Username"
                        />
                    </div>

                    <div className="p-1">
                        <input
                            type="email"
                            label="Email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="form-control form-control-lg"
                            placeholder="Email address"
                        />
                    </div>

                    <div className="p-1">
                        <input
                            type="password"
                            label="Create password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="form-control form-control-lg"
                            placeholder="Password"
                        />
                    </div>
                </div>
                <br/>
                <div>
                    <button
                        type="submit"
                        className="btn btn-dark btn-lg"
                    >
                        Sign up
                    </button>
                </div>


            </form>
            <br/>
            <p className="text-sm text-white text-center">
                <NavLink to="/login" className=" text-tertiary">
                    Sign in
                </NavLink>
            </p>

          </div>
        </div>
        </center>
      </div>

      <footer>
        <div className="row">
          <div className="col">
            <span className="p-2">
              <a href="mailto:yo@getvocal.com">Contact</a>
            </span>
            <span className="p-2">
              <a href="https://twitter.com/yotifications">Twitter</a>
            </span>
            <span className="p-2">
              <a href="/terms">Terms</a>
            </span>
            <span className="p-2">
              <a href="/privacy">Privacy</a>
            </span>
            <span className="p-2">
              <a href="https://blog.yo.im">Blog</a>
            </span>
            <span className="p-2">
              <a href="/login">API</a>
            </span>
          </div>
          <div className="col text-end">
              &copy; {(new Date().getFullYear())}
          </div>
        </div>
      </footer>
    </Container>
    </>
  )
}

export default Signup
