import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import '../App.css';

const About = () => {

  return (
    <>
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/"><img src="/yo-72.png"/></Navbar.Brand>
      </Container>
    </Navbar>
    <Container>
      <div className="row">
        <div className="col">
        <br/>
        <h2>Terms of Use</h2>
        <p>Last updated on October 31, 2023.</p>

        <h4>IMPORTANT: PLEASE READ THESE TERMS OF USE CAREFULLY. PRESSING THE “I AGREE” BUTTON, OR OTHERWISE ORDERING, INSTALLING OR USING THE SERVICES (AS DEFINED BELOW) CONSTITUTES ACCEPTANCE OF THIS AGREEMENT ON YOUR BEHALF.</h4>
        <p>The following terms of use ("Agreement") govern your use of the Yo mobile application (the "App") and any of the services (the "Services") offered by GetVocal, inc. ("we", "us" or "our"). By using or visiting the App, you signify your assent to this Agreement. This Agreement applies to all users of the App ("User").</p>
        <p>Your use of any of the Services constitutes your agreement to comply with the terms of this Agreement. If you cannot agree to and/or comply with this Agreement and its requirements, you are expressly prohibited from use of the Services and must immediately exit the App.</p>

        <h4>Privacy Policy</h4>
        <p>In addition to reviewing this Agreement, you should also review our Privacy Policy (“Privacy Policy”), which is incorporated by reference into this Agreement, to better understand how we collect and use information. Your use of the App and the Services constitutes your agreement to Privacy Policy's terms and conditions.</p>
        <p>See our Privacy Policy at <a href="https://yo.im/privacy">https://yo.im/privacy</a>.</p>

        <h4>Use of the App</h4>
        <p>You may view and use the App only in accordance with the terms of this Agreement.</p>
        <p>You agree to use the App only for lawful purposes and you acknowledge that your failure to do so may subject you to civil and criminal liability, all as determined by applicable law.</p>
        <p>In order to access some features of the App, you may be required to create an account. You are solely responsible for the activity that occurs on your account, and you must keep your account password secure.</p>
        <p>We will not be liable for your losses caused by any unauthorized use of your account.</p>

        <h4>General Prohibitions</h4>
        <p>You agree not to do any of the following, either directly or indirectly:</p>
        <ol>
          <li>Use the App or Services for any illegal purpose, or in violation of any local, state, national, or international law, including, without limitation, laws governing intellectual property and other proprietary rights, and data protection and privacy;</li>
          <li>Remove, circumvent, disable, damage or otherwise interfere with security-related features of the App or Services, features that prevent or restrict use or copying of any content accessible through the App, or features that enforce limitations on the use of the App;</li>
          <li>Reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of the App or any part thereof;</li>
          <li>Modify, adapt, translate or create derivative works based upon the App or any part thereof;</li>
          <li>Intentionally interfere or attempt to interfere with or damage proper operation of the App or Services or any User’s enjoyment of them, by any means, including uploading or otherwise disseminating viruses, adware, spyware, worms, or other malicious code.</li>
          <li>Impose or attempt to impose on the App an unreasonably large load on App's infrastructure or make automated use of the Services.</li>
          <li>Reproduce, duplicate, copy, sell, resell, visit, or otherwise exploit the App or Services for any commercial purpose.</li>
          <li>Encourage or enable any other individual to do any of the foregoing.</li>
        </ol>

        <h4>Changes to Agreement and Additional Rules of Usage</h4>
        <p>We reserve the right, at any time and without prior notice, to modify, alter or update this Agreement. Your continued use of the App constitutes your agreement to comply with Updated Agreement.</p>

        <h4>Change, Suspension or Termination of App, Services or Your Use</h4>
        <p>We may, without prior notice, change the App or Services, stop providing the App or Services, or create usage limits for the App or Services.</p>
        <p>We may permanently or temporarily terminate, limit or suspend your access to the App or Services without notice or liability, for any reason or for no reason.</p>
        <p>We reserve the right to force forfeiture of any username for any reason.</p>
        <p>We may make changes to the materials, products, features and services available on the App at any time without notice.</p>

        <h4>Intellectual Property</h4>
        <p>The App including patents, copyrights, logos, trademarks and trade secrets are legally owned by us or third parties. These rights apply inter alia to the computer code of the App, graphical design, products, company names, etc. All contents of the App are protected by copyright and trademark rights, except as specifically permitted herein.</p>

        <h4>License</h4>
        <p>Upon your decision to use the App and subject to your compliance with the terms of this Agreement, we grant you a limited, non-exclusive, non-transferable, revocable license, without the right to sublicense, for you to install, access and use the App solely for your personal and non-commercial purposes. We reserve all rights in the App not expressly granted to you hereunder.</p>


        <h4>Disclaimer</h4>

        <p>The App, Services, and other material available through the App are provided "As Is", "at Your Own Risk" and "As Available" without any warranties whatsoever, express or implied. We assume no responsibility for the accuracy and completeness of the Services or other material on the App.</p>

        <p>All other warranties, express or implied, including any warranties of merchantability, fitness for any particular purpose, or non-infringement of intellectual property are specifically excluded and disclaimed. We do not warrant that the Services will meet User's requirements and that the operation of the Services will be uninterrupted or error free, or that the App or the server that makes them available are free of viruses or bugs. We make no warranty regarding the quality of any products, service, information or content obtained through the App or Services or the accuracy, timeliness, truthfulness, completeness or reliability of any content obtained through the App or Services.</p>


        <h4>Limitation of Liability</h4>

        <p>We, our affiliates, directors, employees, agents, representatives and third party service providers, will not be liable for any damages whatsoever (including, without limitation, those resulting from lost profits, lost savings, lost data, business interruption or other special, consequential or incidental damages) arising out of or relating to the use, inability to use, or the results of use of the App, whether based on warranty, contract, tort or any other legal theory and whether or not we have been advised of the possibility of such loss or damages.</p>

        <p>In no event will our aggregate liability arising out of or in connection with this Agreement or from the use of or inability to use the App or Services exceed the amount you have paid us during the ninety (90) days prior to your claim (if any). The limitations of damages set forth above are fundamental elements of the basis of the bargain between us and you. Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, so the above limitation may not apply to you.</p>


        <h4>Indemnification</h4>

        <p>You agree to indemnify us and our affiliates, directors, employees, agents, representatives and third party service providers, and to defend and hold us and each of them harmless, from any and all claims, actions, liabilities, damages, losses, expenses and costs (including attorney's fees) (collectively, “Claims”) which may arise from your access to or use of the App, Services or information obtained through use of the App, any content that you submit to this App and/or your breach of this Agreement. We reserve the right to assume the exclusive control of any such defense of any such Claim.</p>


        <h4>App Availability</h4>

        <p>We shall not be responsible for any disruption to the App, regardless of length. We will not be liable for losses related to User's inability to access content due to disruptions of the App. We shall be excused from performance of any obligations under this Agreement which are prevented or interfered due to acts or conditions beyond our reasonable control.</p>


        <h4>The Security of the App</h4>

        <p>We maintain reasonable physical, electronic, and procedural safeguards and personnel policies, consistent with applicable laws that are designed to guard the Services, the App and our systems. Unfortunately, no data transmission over the Internet can be guaranteed to be absolutely secure. In the event of a breach of the confidentiality or security of your information, we will reasonably attempt to notify you as necessary so you can take appropriate protective steps. Unless you indicate otherwise, we may notify you under such circumstances using your most current email address on record with us.</p>


        <h4>Jurisdiction and Governing Law</h4>

        <p>We make no representation that the Services offered through the App are appropriate, available or legal in any particular location. Those who choose to access the Services offered through the App do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable.</p>

        <p>The User understands and agrees that, in addition to money damages, we shall be entitled to equitable relief where appropriate upon User's breach of any portion of this Agreement.</p>

        <p>Any claim relating to the App or use of the App will be governed by and interpreted in accordance with the laws of Maricopa County, Arizona, USA, without reference to its conflict-of-laws principles. Any dispute arising out of or related to your use of the App will be brought in, and you hereby consent to the exclusive jurisdiction and venue in, the competent courts in the Maricopa County, Arizona, USA. You hereby agree to waive all defenses of lack of personal jurisdiction and forum non-conveniens and agree that process may be served in a manner authorized by applicable law or court rule. If any term or provision of this legal notice is for any reason held to be invalid, such invalidity shall not affect any other term or provision, and this legal notice shall be interpreted as if such term or provision had never been contained in this legal notice.</p>


        <h4>Miscellaneous</h4>

        <p>User agrees that no joint venture, partnership, employment or agency relationship exists between User and us as a result of this Agreement and/or User's use of the App. User may not assign any part of its rights and obligations under this Agreement without our prior written consent. No waiver of any obligation or right of either party shall be effective unless in writing, executed by the party against whom it is being enforced. The terms of this Agreement are severable and may be construed to the extent of their enforceability in light of the parties' mutual intent. The titles and subtitles in this Agreement are used for convenience only and are not to be considered in construing it.</p>

        <p>You may contact us at <a href="mailto:yo@getvocal.com">yo@getvocal.com</a>.</p>


        </div>
      </div>

      <footer>
        <div className="row">
          <div className="col">
            <span className="p-2">
              <a href="/about">About</a>
            </span>
            <span className="p-2">
              <a href="https://blog.yo.im">Blog</a>
            </span>
            <span className="p-2">
              <a href="/terms">Terms</a>
            </span>
            <span className="p-2">
              <a href="/privacy">Privacy</a>
            </span>
            <span className="p-2">
              <a href="/login">Developers</a>
            </span>
          </div>
          <div className="col text-end">
              &copy; {(new Date().getFullYear())}
          </div>
        </div>
      </footer>
    </Container>
    </>
  )
}

export default About
